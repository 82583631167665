<template>
  <b-button
    variant="primary"
    class="mr-1"
    @click="changeAlertStatus"
  >Find Request </b-button>

</template>

<script>
import axios from 'axios'

export default {
  name: 'FindRequest',
  props: {
    getAllDataPass: {
      type: Function,
      required: true,
    },
  },
  methods: {
    changeAlertStatus() {
      this.$swal.fire({
        title: 'Find New Request',
        html: `
        <label for="swal-input1" class="w-100 text-left font-weight-bold" style="font-size: 20px;">Request Number</label>
        <input type="text" id="swal-input1" class="swal2-input" placeholder="Enter request number">
        <label for="swal-input1" class="w-100 text-left font-weight-bold" style="font-size: 20px;">Request Source</label>
        <select id="swal-input2" class="swal2-input">
            <option value="" class="text-muted">Select Request Source</option>
            <option value="jeem">Jeem</option>
            <option value="saber-shipment">Saber Shipment</option>
            <option value="saber-conformity">Saber Conformity</option>
            <option value="saber-categorized-conformity">Saber Categorized Conformity</option>
        </select>

        <div id="extra-input-container" style="display: none;">
            <label for="swal-input3" class="w-100 text-left font-weight-bold" style="font-size: 20px;">Region</label>
            <select id="swal-input3" class="swal2-input">
            <option value="" class="text-muted">Select Region</option>
            <option value="Dubai">Dubai</option>
            <option value="Manila">Manila</option>
            <option value="Hungary">Hungary</option>
        </select>
        </div>
    `,
        showCancelButton: true,
        confirmButtonText: 'Find',
        focusConfirm: false,
        didOpen: () => {
          document.getElementById('swal-input2').addEventListener('change', event => {
            const selectedValue = event.target.value
            const extraInputContainer = document.getElementById('extra-input-container')

            if (selectedValue === 'saber-shipment' || selectedValue === 'saber-conformity' || selectedValue === 'saber-categorized-conformity') {
              extraInputContainer.style.display = 'block'
            } else {
              extraInputContainer.style.display = 'none'
            }
          })
        },
        preConfirm: () => {
          const input1 = document.getElementById('swal-input1').value
          const input2 = document.getElementById('swal-input2').value
          const input3 = document.getElementById('swal-input3') ? document.getElementById('swal-input3').value : ''

          if (!input1 || !input2) {
            this.$swal.showValidationMessage('Both fields are required!')
            return false
          }

          // Require input3 only if it's visible
          if ((input2 === 'saber-shipment' || input2 === 'saber-conformity' || input2 === 'saber-categorized-conformity') && !input3) {
            this.$swal.showValidationMessage('Region is required!')
            return false
          }

          const requestData = {
            code: input1,
            source: input2,
          }
          if (input2 !== 'jeem') {
            requestData.region = input3
          }
          return requestData
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.$swal.fire({
            title: 'Searching...',
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
              axios.post('https://gulftic-system.fci.group/api/urgent-request', result.value)
                .then(response => {
                  this.$swal.fire({
                    title: 'Success',
                    text: 'Request found successfully',
                    icon: 'success',
                  })
                  if (response.status === 200) {
                    this.getAllDataPass(1)
                  }
                })
                .catch(error => {
                  this.$swal.fire({
                    title: `Error ${error.response?.status || ''}`,
                    text: 'No data found for request',
                    icon: 'error',
                  })
                })
            },
          })
        }
      })
    },
  },

}
</script>
